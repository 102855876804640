import styled from "styled-components";

export const CallBtnContainer = styled.div`
    width:5rem;
    height:5rem;
    background:transparent;
    position: fixed;
    right:0;
    bottom:30%;
`

export const CallBtnIconLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`

export const CallBtnIcon = styled.img`
    width:100%;
`