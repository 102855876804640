import React from "react";
import {HeaderLogos,LogoCdn,Logo,BoschComfort,HeaderTimeline} from './Header.style';

const Header = () => {
    return (
        <HeaderLogos>
                    <HeaderTimeline></HeaderTimeline>
                    <LogoCdn src="/assets/cdn_logo1.png" />
                    <Logo src="/assets/bosch_logo.png" />
                    <BoschComfort src="assets/bosch_comfort_logo.png" />
                    {/* <HeaderContact>

                        <HeaderContactContainer>
                            <HeaderContactLogo>A</HeaderContactLogo>
                            <HeaderContactText><Link to="/about">About</Link></HeaderContactText>
                        </HeaderContactContainer>

                        <HeaderContactContainer>
                            <HeaderContactLogo>C</HeaderContactLogo>
                            <HeaderContactText>Contact US</HeaderContactText>
                        </HeaderContactContainer>

                        <HeaderContactContainer>
                            <HeaderContactLogo>S</HeaderContactLogo>
                            <HeaderContactText>Search</HeaderContactText>
                        </HeaderContactContainer>

                        <HeaderContactContainer>
                            <HeaderContactLogo>Z</HeaderContactLogo>
                            <HeaderContactText>Zood</HeaderContactText>
                        </HeaderContactContainer>
                    </HeaderContact> */}
                </HeaderLogos>
    )
}

export default Header;