import styled from 'styled-components';
import { device } from '../../responsive-sizez';

export const StripHeader = styled.div`
    width:100%;
    position:absolute;
    top:calc(90vh + .375rem);
    z-index:-1;
    height:16vh;
    background:#fff;

    @media ${device.desktop_2560px} {
        top:calc(80vh + .375rem);
    }

    @media ${device.desktop_2300px} {
        top:calc(70vh + .375rem);
    }

    @media ${device.desktop_1730px} {

    }

    @media ${device.desktop_1370px} {

    }

    @media ${device.laptop_1024px} {

    }

    @media ${device.laptop_1024px} and (max-height: 600px) {
        top:calc(60vh + 1rem);
    }

    @media ${device.laptop_1024px} and (max-height: 550px) {
        top:calc(50vh + 1rem);
    }


    @media ${device.tablet_768px} {
        top:calc(50vh + .375rem);
    }

    @media ${device.mobileL_550px} {
        top:calc(40vh + 2rem);
        height:7vh;
    }

    @media ${device.mobileL_550px} and (max-height: 720px) {
        top:calc(45vh + .375rem);
    }

    @media ${device.mobileL_425px} {
        top:calc(30vh + 2rem);
    }
`
export const StripHeaderHeading = styled.div`
        background: linear-gradient(to right, #007bc0, #008ecf);
        width:100%;
        height:5rem;
        position:absolute;
        top:50%;
        text-align:center;
        padding: 1rem 0;
        transform: translateY(-50%);
        
        @media ${device.desktop_1370px} {
            height:7rem;
    }

        @media ${device.laptop_1024px} and (max-height: 550px) {
            top:16vh;
    }
    
        @media ${device.mobileL_550px} {

            height:10rem;
        }

        @media ${device.mobileL_550px} and (max-height: 720px) {
            height:9rem;
    }

        @media ${device.mobileL_425px} {
            height:7rem;
        }
`
export const StripHeaderHeadingText = styled.h1`
        font-family:Arial;
        color:#fff;
        font-size:2rem;
        width:80%;
        margin: 0 auto;
        font-weight:100;
        @media ${device.mobileL_425px} {
            font-size:1.5rem;
            width:95%;
    }
`