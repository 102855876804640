import React from "react";
import { database } from "../../database";
import { SectionWrapper,SectionMax,SectionHeader,SectionHeading,SectionText,SectionImgContainer } from "./Section.style";
import { Card } from "../Card/Card.component";

const Section = ({SectionHeaderText,SectionHeaderSecondText,cardData,Last,boiler}) => {
    return (
        <SectionWrapper>
        <SectionMax>

            <SectionHeader>
                <SectionHeading>
                    {database[SectionHeaderText].SectionHeaderText} 
                </SectionHeading>
                <SectionText>
                    {database[SectionHeaderSecondText].SectionHeaderSecondText} 
                </SectionText>
            </SectionHeader>

            <SectionImgContainer>
            
                {cardData.map((item,index) => (
                    <Card
                    key={index} 
                    imageSrc={database[item].imageSrc}
                    headerText={database[item].headerText}
                    contentText={database[item].contentText}
                    linkHref={database[item].linkHref}
                    Last = {Last ? true : false}
                    boiler = {boiler ? true : false}
                    >
                </Card>
                ))}
                
            </SectionImgContainer>

        </SectionMax>
    </SectionWrapper>
    )
}

export default Section;