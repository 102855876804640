import React from "react";
import { StripHeader,StripHeaderHeading,StripHeaderHeadingText } from "./StripLine.style";

const StripLine = () => {
    return (
        <StripHeader>
            <StripHeaderHeading>
                <StripHeaderHeadingText>
                    Offering in Lebanon professional air conditioning,ventilation, heating and hot water solutions from Bosch Thermotechnology.
                </StripHeaderHeadingText>
            </StripHeaderHeading>
        </StripHeader>
    )
}

export default StripLine;