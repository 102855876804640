import React from "react";
import { FooterContainer, FooterIcons, FooterIconsLink } from "./Footerline.style";
import ReactGA from 'react-ga';

const FooterLine = () => {

    const eventTrack = () => {
      
        ReactGA.ga('event', 'conversion', {
        'send_to': 'AW-11003972766/M6V2COfF5eUDEJ6Zjf8o'
         });
      }

    return (
        <FooterContainer>

            <FooterIconsLink href="https://www.facebook.com/BoschClimateLb" target="_blank">
                <FooterIcons src="/assets/facebook.png" alt="Facebook Icon" title="Facebook Icon"/>
            </FooterIconsLink>

            <FooterIconsLink href="https://www.instagram.com/boschclimatelb/" target="_blank">
                <FooterIcons src="/assets/instagram.png"/>
            </FooterIconsLink>

            <FooterIconsLink href="http://m.me/BoschClimateLb" target="_blank">
                <FooterIcons src="/assets/messenger.png"  small={true} />
            </FooterIconsLink>

            <FooterIconsLink href="https://api.whatsapp.com/send?phone=+9611799111&text=Hi, I would like to know more about your products" target="_blank" onClick={eventTrack.bind(this)}>
                <FooterIcons src="/assets/whatsapp.gif"/>
            </FooterIconsLink>

        </FooterContainer>
    )
};

export default FooterLine;