import styled from 'styled-components';
import {device} from "../../responsive-sizez";

export const CarouselContainer = styled.div`
    position:absolute;
    top:0;
    width:90%;
    background:#fff;
    height:90vh;
    left:50%;
    transform: translateX(-50%);

    @media ${device.desktop_2560px} {
        height:80vh;
    }

    @media ${device.desktop_2300px} {
        height:70vh;
    }

    @media ${device.desktop_1730px} {

    }

    @media ${device.laptopL_1440px} {
        width:100%
    }
    
    @media ${device.desktop_1370px} {

    }

    @media ${device.laptop_1024px} {
 
    }

    @media ${device.laptop_1024px} and (max-height: 600px) {
        height:60vh;
    }

    @media ${device.laptop_1024px} and (max-height: 550px) {

    }

    @media ${device.tablet_768px} {
        height:50vh;
    }

    @media ${device.mobileL_550px} {
        height:40vh;
    }

    @media ${device.mobileL_550px} and (max-height: 720px) {
        height:40vh;
    }

    @media ${device.mobileL_425px} {
        height:30vh;
    }
`