import styled from 'styled-components';
import { device } from '../../responsive-sizez';

export const SectionWrapper = styled.div`
    width:100%;
    background:#fff;
`
export const SectionMax = styled.div`
    margin:0 auto;
    width:90%;
    display:flex;
    flex-direction:column;
    
    @media ${device.laptop_1024px} {
        width:100%;
    }

    @media ${device.tablet_822px} {
        width:70%;
    }

    @media ${device.mobileL_425px} {
        width:100%;
    }
`
export const SectionHeader = styled.div`
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin:2rem 1rem;

    @media ${device.mobileL_425px} {
        margin:0 auto;
        text-align:center;
    }
`
export const SectionHeading = styled.h1`
    margin:1rem 0;
    font-size:3.2rem;

    @media ${device.mobileL_550px} {
        font-size:2.7rem;
    }
`
export const SectionText = styled.p`
    font-size:1.5rem;
    font-weight:600;
`
export const SectionImgContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction:row;
    justify-content:center;

    @media ${device.tablet_822px} {
        flex-wrap: wrap;
    }
`