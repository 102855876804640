import React from "react";
import { CallBtnContainer, CallBtnIcon, CallBtnIconLink } from "./call.style";
import ReactGA from 'react-ga';

const CallServiceBtn = () => {
    const eventTrack = () => {
          
        ReactGA.ga('event', 'conversion', {
        'send_to': 'AW-11003972766/M6V2COfF5eUDEJ6Zjf8o'
         });
      }
    return (
        <CallBtnContainer>
            <CallBtnIconLink href="https://api.whatsapp.com/send?phone=+9611799111&text=Hi, I would like to know more about your products" target="_blank" onClick={eventTrack.bind(this)}>
                <CallBtnIcon src="/assets/service-call.png" />
            </CallBtnIconLink>
        </CallBtnContainer>
    )
}

export default CallServiceBtn