import React from 'react';
import HomePage from './pages/homepage/homepage.component';
import './App.css';
import ReactGA from 'react-ga';

const App = () => {
  ReactGA.initialize('AW-11003972766');
  ReactGA.pageview(window.location.pathname);
  return (
    <div className="App">
      <HomePage></HomePage>
    </div>
  )
}

export default App;
