import styled from "styled-components";
import { device } from "../../responsive-sizez";

export const FooterContainer = styled.div`
    position:fixed;
    bottom:0;
    width:100%;
    height:5vh;
    background: #fff;
    display:flex;
    justify-content:space-around;
    align-items: center;


    @media ${device.laptopL_1440px} and (max-height: 550px) {
        height:7vh;
    }
`
export const FooterIconsLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    color: transparent; 
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`
export const FooterIcons = styled.img`
    width:${props => props.small ? 3 : 4}vh;

    @media ${device.laptopL_1440px} and (max-height: 550px) {
        width:${props => props.small ? 5 : 6}vh;
    }
`