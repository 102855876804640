import styled from "styled-components";
import { device } from "../../responsive-sizez";

export const CardContainer = styled.div`
    display:flex;
    flex-flow:column;
    margin:1rem;
    margin-bottom:${props => props.Last ? 5 : 0}vh;
    position:relative;
    width:${props => props.Last? 100 : 33.333333}%;
    background: #fff;
    outline: none;
    border-color: #eff1f2;
    box-shadow: 0 0 10px #eff1f2;

    @media ${device.laptop_1024px} {
        width:90%;
    }

    @media ${device.mobileL_425px} {
        margin-top:4rem;
    }
`
export const CardImg = styled.img`
    width:${props => props.boiler? 80 : 100}%;
    height:${props => props.boiler? '30rem' : '100%'};
    margin:${props => props.boiler? '0 auto' : 0};
`
export const CardContent = styled.div`
    display: flex;
    flex-flow: column;
    padding: 20px 0;
    justify-content:center;
    align-items:center;
    @media ${device.mobileL_425px} {
        
    }
`
export const CardContentHeading = styled.h4`
    margin-top: 0;
    font-size: 2rem;
    margin-bottom:1rem;


    @media ${device.tablet_822px} {
        font-size:2.5rem;
    }

    @media ${device.mobileL_425px} {
        font-size:2.8rem;
    }
`
export const CardContentText = styled.p`
    
    font-family:  Helvetica, Arial, sans-serif;

    margin: 0 0 15px;
    padding:5px;
    font-weight:400;
    font-size:1.2rem;
    @media ${device.mobileL_425px} {
        font-size:1.5rem;
        line-height: 2rem;
    }
`
export const BtnLink = styled.a`
    font-family:  Helvetica, Arial, sans-serif;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    user-select: none;
    text-decoration: none;
    padding: 13px 20px;
    font-size: 16px;
    line-height: 1.3;
    border-radius: 1px;
    transition: 0.5s;
    white-space: normal;
    height: auto;
    overflow: hidden;
    color: #000;
    background-color: transparent;
    border-color: #bfc0c2;
    opacity: 1;
    width:70%;
    align-self:center;
    margin: 1rem auto;
`