import React from 'react'
import CarouselBlock from '../../Components/Carousel_Block/Carousel_Block';
import Header from '../../Components/Header/Header';
import { PageContainer, SectionsContainer} from './homepage.style';
import FooterLine from '../../Components/FooterLine/FooterLine.component';
import CallServiceBtn from '../../Components/call-service/call.component';
import ReactGA from 'react-ga';
import StripLine from '../../Components/StripLine/StripLine';
import Section from '../../Components/Sections/Section';
import ContactUs from '../../Components/contactUs/contactUs';


const Homepage = () => {

    const eventTrack = () => {
          
        ReactGA.ga('event', 'conversion', {
        'send_to': 'AW-11003972766/M6V2COfF5eUDEJ6Zjf8o'
         });
      }
    
    return (
        <div>

            <PageContainer>

                <Header></Header>
            
                <CarouselBlock></CarouselBlock>

                <StripLine></StripLine>

                <SectionsContainer>

                    <Section SectionHeaderText={"Air_Conditioning"} SectionHeaderSecondText={"Air_Conditioning"} cardData={["Air_Conditioning","Ventilation"]}></Section>
                    
                    <Section SectionHeaderText={"Thermosiphon"} SectionHeaderSecondText={"Thermosiphon"} cardData={["Thermosiphon","Collectors","Gas_Water"]}></Section>

                    <Section SectionHeaderText={"Boilers"} SectionHeaderSecondText={"Boilers"} cardData={["Boilers","Steam", "Combi"]} boiler={true}></Section>

                    <Section SectionHeaderText={"Large_Solar"} SectionHeaderSecondText={"Large_Solar"} cardData={["Large_Solar"]} Last={true}></Section>

                    <ContactUs eventTrack={eventTrack}></ContactUs>

                </SectionsContainer>

            </PageContainer>
            <CallServiceBtn></CallServiceBtn>
            <FooterLine></FooterLine>
        </div>
    )
}

export default Homepage;