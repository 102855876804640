import styled from 'styled-components';
import { device } from '../../responsive-sizez';


export const PageContainer = styled.div`
    background-color: #fff;
    margin: 0 auto;
    max-width: 100%;
    position:relative;
`

export const SectionsContainer = styled.div`
    width:100%;
    position:absolute;
    background:#fff;
    top:calc(90vh + 16vh);

    @media ${device.desktop_2560px} {
        top:calc(80vh + 16vh);
    }

    @media ${device.desktop_2300px} {
        top:calc(70vh + 16vh);
    }

    @media ${device.desktop_1730px} {

    }

    @media ${device.desktop_1370px} {

    }

    @media ${device.laptop_1024px} {

    }

    @media ${device.laptop_1024px} and (max-height: 600px) {
        top:calc(60vh + 16vh);
    }

    @media ${device.laptop_1024px} and (max-height: 550px) {

    }

    @media ${device.tablet_768px} {
        top:calc(50vh + 16vh);
    }


    @media ${device.mobileL_550px} {
        top:calc(40vh + 7vh + 3rem);
    }

    @media ${device.mobileL_550px} and (max-height: 720px) {
        top:calc(50vh + 5rem);
    }

    @media ${device.mobileL_425px} {
        top:calc(30vh + 7vh + 3rem);
    }
`

