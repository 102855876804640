import React from "react";
import {ContactUsWrapper, ContactUsContainer, ContactUsLeft, ContactUsRight, ContactUsContent, ContactUsContentHeading, ContactUsContentRow, ContactUsLine, ContactUsIconLink, ContactUsIconSpan} from "./contactUs.style";

const ContactUs = ({eventTrack}) => {
    return (
        <ContactUsWrapper>

        <ContactUsContainer>
    
            <ContactUsLeft>
                <ContactUsContent>

                    <ContactUsContentHeading>
                        CDN - Sales
                    </ContactUsContentHeading>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@cdn.com.lb" target="_self">

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-address-card"></i>
                            </ContactUsIconSpan>      

                            Rawche Street, Fawaz building, Beirut, 1st Floor
                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="tel:+961 1 799 111" target="_self" onClick={eventTrack.bind(this)}>

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-phone"></i>
                            </ContactUsIconSpan>      

                            +961 1 799 111

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@cdn.com.lb">

                            <ContactUsIconSpan>
                                <i className="fa-solid fa-envelope"></i>
                            </ContactUsIconSpan> 

                            info@cdn.com.lb

                        </ContactUsIconLink>
                        
                    </ContactUsContentRow>

                </ContactUsContent>
            </ContactUsLeft>

            <ContactUsLine></ContactUsLine>

            <ContactUsRight>
                <ContactUsContent>

                    <ContactUsContentHeading>
                        CDN - After Sales
                    </ContactUsContentHeading>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@cdn.com.lb" target="_self">

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-address-card"></i>
                            </ContactUsIconSpan>      

                            Abdalla Al Mashnook Street, Palm Building, 1st Floor

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="tel:+961 1 799 111" target="_self" onClick={eventTrack.bind(this)}>

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-phone"></i>
                            </ContactUsIconSpan>      

                            +961 1 799 111

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@cdn.com.lb">

                            <ContactUsIconSpan>
                                <i className="fa-solid fa-envelope"></i>
                            </ContactUsIconSpan> 

                            info@cdn.com.lb

                        </ContactUsIconLink>
                        
                    </ContactUsContentRow>

                </ContactUsContent>
            </ContactUsRight>

        </ContactUsContainer>
        
    </ContactUsWrapper>
    )
}

export default ContactUs;