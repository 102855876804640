const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '430px',
    mobileL2: '550px',
    tablet: '768px',
    tablet_L:'822px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
    desktop_2300: '2300px',
    desktop_1730: '1730px',
    desktop_1370: '1370px',
}

export const device = {
    mobileS_320px: `screen and (max-width: ${size.mobileS})`,
    mobileM_375px: `screen and (max-width: ${size.mobileM})`,
    mobileL_425px: `screen and (max-width: ${size.mobileL})`,
    mobileL_550px: `screen and (max-width: ${size.mobileL2})`,
    tablet_768px: `screen and (max-width: ${size.tablet})`,
    tablet_822px: `screen and (max-width: ${size.tablet_L})`,
    laptop_1024px: `screen and (max-width: ${size.laptop})`,
    laptopL_1440px: `screen and (max-width: ${size.laptopL})`,
    desktop_2560px: `screen and (max-width: ${size.desktop})`,
    desktop_min_2560: `screen and (min-width: ${size.desktop})`,
    desktop_2300px: `screen and (max-width: ${size.desktop_2300})`,
    desktop_1730px: `screen and (max-width: ${size.desktop_1730})`,
    desktop_1370px: `screen and (max-width: ${size.desktop_1370})`,
};